[]
<template>
  <main-content-box>
    <template #main>
      <div class="contestItemManagement__row">
        <contest-select-group :defaultSelectedInfo="contestItemSelectedId" :selectHandler="itemSelectHandler" :mode="SECTOR"></contest-select-group>
      </div>

      <v-row style="margin-bottom: 10px" v-if="registInfos.length > 0">
        <v-col class="text-right">
          <contest-item-editable-dialog
            title="종목관리 추가하기"
            :items="registInfos"
            :activeHandler="registContestItemHandler"
            activeLabel="추가하기"
            color="indigo"
          ></contest-item-editable-dialog>
        </v-col>
      </v-row>

      <div>
        <v-row>
          <v-col v-for="column in CONTEST_ITEM_HEADER[mode]" :key="column.id" class="contestItemManagement__title text-center">
            {{ column.title }}
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row v-if="displayGrids.length === 0 || displayGrids[0].length === 0">
          <v-col class="text-center">데이터가 없습니다.</v-col>
        </v-row>
        <v-row v-else v-for="(columns, index) in displayGrids" :key="`contestItemManagementGrid-${index}`">
          <v-col class="text-center" v-for="column in columns" :key="column.id">
            <contest-item-editable-dialog
              v-if="column.id === 'edit'"
              title="종목관리 수정하기"
              :items="editInfos"
              :activeHandler="editContestItem"
              @update:openDialog="column.onClick"
              activeLabel="수정하기"
              color="light-green"
              :id="column.rowId"
            ></contest-item-editable-dialog>

            <span v-else>{{ column.label }}</span>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </div>
    </template>
  </main-content-box>
</template>

<script>
import { ref } from 'vue';

import { useContestStore } from '@/store/contest';
import MainContentBox from '@/components/MainContentBox.vue';
import { CONTEST_ITEM_HEADER, CLASSIFICATION, CONTEST, SECTOR, EVENT } from '@/constants/contest/gridHeader';
import ContestSelectGroup from '@/views/components/contest/ContestSelectGroup.vue';
import ContestItemEditableDialog from '@/views/components/contest/ContestItemEditableDialog.vue';
import { infoToast, successToast } from '@/utils/toastUtil';
import { PLAYER_INFO_TITLE } from '@/constants/contest';

const makeDefaultColumn = (col) => ({
  id: col.id,
  editable: col.editable,
  label: col.title,
  type: col.type ?? 'text',
});

const makeRegistColumn = (prevRow, currentCol) => ({
  ...makeDefaultColumn(currentCol),
  title: currentCol.editable ? ref('') : prevRow[currentCol.id],
});

const makeEditColumn = (row, col) => ({
  ...makeDefaultColumn(col),
  title: ref(row[col.id]),
});

const makeReadonlyColumn = (row, col) => ({
  ...makeDefaultColumn(col),
  title: row[col.id],
  displayStatus: !['edit', 'delete'].includes(col.id),
});

/**
 * 종목 관리 페이지
 */
export default {
  name: 'ContestItemManagement',
  components: { MainContentBox, ContestSelectGroup, ContestItemEditableDialog },
  setup() {
    const contestStore = useContestStore();
    const mode = ref(CLASSIFICATION);
    const contestRegisterType = ref({});
    const displayGrids = ref([[]]);
    const registInfos = ref([]);
    const editInfos = ref([]);
    const deleteInfos = ref([]);

    const contestItemSelectedId = ref({
      [CONTEST]: '',
      [CLASSIFICATION]: '',
      [SECTOR]: '',
      [EVENT]: '',
    });

    const currentId = {
      contest: '',
      parent: '',
    };

    const registContestItemHandler = async (items) => {
      const [title, code] = items.filter((row) => ['title', 'code'].includes(row.id));
      const param = {
        contestId: currentId.contest,
        parentId: currentId.parent,
        name: title.title,
        code: code.title,
        type: mode.value,
      };

      param.contestRegisterType = items.find((row) => row.id === 'contestRegisterType')?.title;

      if (contestRegisterType.value) {
        for (const [key, val] of Object.entries(PLAYER_INFO_TITLE)) {
          if (val === contestRegisterType.value.title) {
            param.contestRegisterType = key;
          }
        }
      }
      const result = await contestStore.registContestItem({ ...param });
      if (result) {
        displayGrids.value = contestStore[mode.value].map((row, index) => CONTEST_ITEM_HEADER[mode.value].map((column) => makeDisplayColumn(row, column, index)));
        successToast('데이터를 추가했습니다.');
      }
    };

    const editContestItem = async (items, id) => {
      const { title, code } = items.reduce((acc, curRow) => {
        if (['title', 'code'].includes(curRow.id)) {
          acc[curRow.id] = curRow.title;
          return acc;
        }
        return acc;
      }, {});
      const result = await contestStore.updateContestItem(currentId.contest, currentId.parent, id, title, code, mode.value);
      if (result) {
        displayGrids.value = contestStore[mode.value].map((row, index) => CONTEST_ITEM_HEADER[mode.value].map((column) => makeDisplayColumn(row, column, index)));
        successToast('데이터를 수정했습니다.');
      }
    };

    const deleteContestItem = async () => {
      infoToast('준비중인 기능입니다.');
    };

    const columnMakeHandler = (row, columnMaker, customRef) => () => {
      customRef.value = CONTEST_ITEM_HEADER[mode.value].map((column) => columnMaker(row, column));
    };

    const makeDisplayColumn = (row, column) => ({
      ...makeDefaultColumn(column),
      rowId: row.id,
      label: (() => {
        if (column.editable) return ref(row[column.id]);
        else if (column.type === 'button') return column.title;
        else return row[column.id];
      })(),
      onClick: (() => {
        if (column.id === 'edit') return columnMakeHandler(row, makeEditColumn, editInfos);
        else if (column.id === 'delete') return columnMakeHandler(row, makeReadonlyColumn, deleteInfos);
        else return () => {};
      })(),
    });

    const itemSelectHandler = (selectedId, type, target) => {
      const prevRow = contestStore[type].find((row) => row.id === selectedId);

      const registColumnInfos = CONTEST_ITEM_HEADER[target];

      if (target == 'sector') {
        contestRegisterType.value = {
          id: 'contestRegisterType',
          title: prevRow.contestRegisterType,
          editable: true,
        };
      }

      displayGrids.value = contestStore[target].map((row) => registColumnInfos.map((column) => makeDisplayColumn(row, column)));
      registInfos.value = registColumnInfos.map((column) => makeRegistColumn(prevRow, column));
      successToast('데이터 조회가 완료되었습니다.');

      switch (target) {
        case CLASSIFICATION:
          currentId.contest = contestItemSelectedId.value.contest;
          currentId.parent = '';
          break;
        case SECTOR:
          currentId.parent = contestItemSelectedId.value.classification;
          break;
        case EVENT:
          currentId.parent = contestItemSelectedId.value.sector;
          break;
      }
      mode.value = target;
    };

    return {
      CONTEST_ITEM_HEADER,
      mode,
      displayGrids,
      registInfos,
      deleteInfos,
      editInfos,
      CLASSIFICATION,
      SECTOR,
      EVENT,
      contestItemSelectedId,
      registContestItemHandler,
      editContestItem,
      deleteContestItem,
      itemSelectHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.contestItemManagement {
  &__row {
    margin-bottom: 3rem;
  }
  &__title {
    color: black;
    font-weight: 500;
    font-size: 21px;
  }
}
</style>
