import { isEmptyOrIntegerValidator, isIntegerValidator } from '@/utils/validator';

export const CONTEST = 'contest';
export const CLASSIFICATION = 'classification';
export const SECTOR = 'sector';
export const EVENT = 'event';
export const JUDGE_ID_PREFIX = 'judge-';
export const SCORE_JUDGE_LIST = Array(100)
  .fill()
  .map((_, index) => ({
    header: index + 1,
    name: `${JUDGE_ID_PREFIX}${index + 1}`,
    editor: 'text',
  }));

export const CONTEST_MANAGEMENT_HEADER = [
  {
    header: '대회명',
    name: 'title',
    editor: 'text',
  },
  {
    header: '접수 시작일',
    name: 'applicationStartDate',
    editor: {
      type: 'datePicker',
      options: {
        format: 'yyyy년 MM월 dd일 HH:mm A',
        timepicker: true,
        language: 'ko',
      },
    },
  },
  {
    header: '접수 마감일',
    name: 'applicationEndDate',
    editor: {
      type: 'datePicker',
      options: {
        format: 'yyyy년 MM월 dd일 HH:mm A',
        timepicker: true,
        language: 'ko',
      },
    },
  },
  {
    header: '대회 시작일',
    name: 'startDate',
    editor: {
      type: 'datePicker',
      options: {
        format: 'yyyy-MM-dd',
        language: 'ko',
      },
    },
  },
  {
    header: '대회 종료일',
    name: 'endDate',
    editor: {
      type: 'datePicker',
      options: {
        format: 'yyyy-MM-dd',
        language: 'ko',
      },
    },
  },
  {
    header: '대회 장소',
    name: 'location',
    editor: 'text',
  },
];

export const CONTEST_ITEM_HEADER = {
  [CLASSIFICATION]: [
    {
      id: 'contestRegisterType',
      title: '참가 그룹',
      editable: true,
    },
    {
      id: 'title',
      title: '분류명',
      editable: true,
    },
    {
      id: 'code',
      title: '분류코드',
      editable: true,
      validation: {
        validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
        dataType: 'number',
        required: true,
      },
    },
    {
      id: 'edit',
      type: 'button',
      title: '수정',
    },
  ],
  [SECTOR]: [
    {
      id: 'classificationTitle',
      title: '분류명',
    },
    {
      id: 'classificationCode',
      title: '분류코드',
    },
    {
      id: 'title',
      title: '부문',
      editable: true,
    },
    {
      id: 'code',
      title: '부문코드',
      editable: true,
      validation: {
        validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
        dataType: 'number',
        required: true,
      },
    },
    {
      id: 'edit',
      type: 'button',
      title: '수정',
    },
  ],
  [EVENT]: [
    {
      id: 'classificationTitle',
      title: '분류명',
    },
    {
      id: 'classificationCode',
      title: '분류코드',
    },
    {
      id: 'sectorTitle',
      title: '부문',
    },
    {
      id: 'sectorCode',
      title: '부문코드',
    },
    {
      id: 'title',
      title: '종목명',
      editable: true,
    },
    {
      id: 'code',
      title: '종목코드',
      editable: true,
      validation: {
        validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
        dataType: 'number',
        required: true,
      },
    },
    {
      id: 'edit',
      type: 'button',
      title: '수정',
    },
  ],
};
export const PRIZE_PRINT_HEADER = [
  {
    header: '이름',
    name: 'playerName',
    editor: 'text',
    sortable: false,
  },
  {
    header: '수상명',
    name: 'title',
    editor: 'text',
    sortable: false,
  },
  {
    header: '분야',
    name: 'sectionName',
    editor: 'text',
    sortable: false,
  },
];

export const MIRROR_MANAGEMENT_HEADER = [
  {
    header: '참가번호',
    name: 'playerSequence',
    editor: 'text',
    sortable: false,
  },
  {
    header: '이름',
    name: 'playerName',
    sortable: false,
  },
  {
    header: '거울번호',
    name: 'mirrorSequence',
    editor: 'text',
    sortable: false,
    validation: {
      validatorFn: (value) => isEmptyOrIntegerValidator(value) && Number(value) >= 0,
    },
  },
  {
    header: '참석 여부',
    name: 'participationStatus',
    formatter: 'listItemText',
    editor: {
      type: 'select',
      options: {
        listItems: [
          { text: '시작전', value: '시작전' },
          { text: '참여', value: '참여' },
          { text: '결석', value: '결석' },
        ],
      },
    },
  },
];

export const PRIZE_MANAGEMENT_HEADER = [
  {
    header: '대회명',
    name: 'contestTitle',
  },
  {
    header: '분류명',
    name: 'classificationTitle',
  },
  {
    header: '부문',
    name: 'sectorTitle',
  },
  {
    header: '종목명',
    name: 'eventTitle',
  },
  {
    header: '수상명',
    name: 'title',
    editor: 'text',
  },
  {
    header: '수상인원',
    name: 'maxAllowed',
    editor: 'text',
    validation: {
      validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
      dataType: 'number',
      required: true,
    },
  },
  {
    header: '등급',
    name: 'tier',
    editor: 'text',
    validation: {
      validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
      dataType: 'number',
      required: true,
    },
  },
];

export const SCORE_MANAGEMENT_HEADER = [
  {
    header: '거울번호',
    name: 'mirrorSequence',
    width: 80,
  },
  {
    header: '이름',
    name: 'playerName',
    sortable: false,
  },
  {
    header: '감점',
    name: 'takeOffPoint',
    editor: 'text',
    validation: {
      validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
      dataType: 'number',
      required: true,
    },
    sortable: false,
  },
  ...SCORE_JUDGE_LIST.map((item) => ({
    ...item,
    validation: {
      validatorFn: (value) => isIntegerValidator(value) && Number(value) >= 0,
      dataType: 'number',
      required: true,
    },
    sortable: false,
  })),
];

export const WINNER_MANAGEMENT_HEADER = [
  {
    header: '수상명',
    name: 'title',
  },
  {
    header: '순위',
    name: 'rank',
  },
  {
    header: '거울번호',
    name: 'mirrorSequence',
  },
  {
    header: '이름',
    name: 'playerName',
  },
  {
    header: '총점',
    name: 'totalScore',
  },
  {
    header: '감점',
    name: 'takeOffPoint',
  },
  ...SCORE_JUDGE_LIST,
];

export const PUSH_HISTORY_MANAGEMENT_HEADER = [
  {
    header: '발송 uid',
    name: 'uid',
    sortable: false,
  },
  {
    header: '제목',
    name: 'title',
    sortable: false,
  },
  {
    header: '내용',
    name: 'body',
    sortable: false,
  },
  {
    header: '성공 수',
    name: 'successCount',
    sortable: false,
  },
  {
    header: '실패 수',
    name: 'failureCount',
    sortable: false,
  },
  {
    header: '발송일자',
    name: 'createdDate',
    sortable: false,
  },
];
