<template>
  <div>
    <progress-loader v-if="isLoading" />
    <div class="contestSelectGroup__row" v-else>
      <template v-for="rType in renderModeInfo[mode]" :key="renderInfos[rType].type">
        <dropdown
          :list="filteredList(contestStore[renderInfos[rType].type], rType)"
          :selectedItem="selectedInfo[renderInfos[rType].type]"
          :label="renderInfos[rType].label"
          :target="renderInfos[rType].target"
          :type="renderInfos[rType].type"
          :onChangeSelectedItem="onSelectItem"
          :disabled="disabledKey[rType]"
        />
      </template>
      <slot name="button"></slot>
    </div>
    <div>
      <small v-if="errorText !== ''" class="p-2 contestSelectGroup__error">{{ errorText }}</small>
    </div>
  </div>
</template>

<script>
import { onMounted, toRef, computed } from 'vue';
import { useContestStore } from '@/store/contest';
import { useAuthStore } from '@/store/auth';

import ProgressLoader from '@/components/ProgressLoader.vue';
import Dropdown from '@/components/Dropdown.vue';

import { CONTEST, CLASSIFICATION, SECTOR, EVENT } from '@/constants/contest/gridHeader';

const renderModeInfo = {
  [CONTEST]: [CONTEST],
  [CLASSIFICATION]: [CONTEST, CLASSIFICATION],
  [SECTOR]: [CONTEST, CLASSIFICATION, SECTOR],
  [EVENT]: [CONTEST, CLASSIFICATION, SECTOR, EVENT],
};

const renderInfos = {
  [CONTEST]: {
    label: '대회명',
    type: CONTEST,
    target: CLASSIFICATION,
  },
  [CLASSIFICATION]: {
    label: '분류명',
    type: CLASSIFICATION,
    target: SECTOR,
  },
  [SECTOR]: {
    label: '부문명',
    type: SECTOR,
    target: EVENT,
  },
  [EVENT]: {
    label: '종목명',
    type: EVENT,
    target: '',
  },
};

export default {
  components: { Dropdown, ProgressLoader },
  props: {
    defaultSelectedInfo: {
      type: Object,
      default: () => ({
        [CONTEST]: '',
        [CLASSIFICATION]: '',
        [SECTOR]: '',
        [EVENT]: '',
      }),
    },
    selectHandler: {
      type: Function,
      default: () => {},
    },
    mode: {
      type: String,
      default: CONTEST,
    },
    errorText: {
      type: String,
      default: '',
    },
    disabledKey: {
      type: Object,
      default: () => ({
        [CONTEST]: false,
        [CLASSIFICATION]: false,
        [SECTOR]: false,
        [EVENT]: false,
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const contestStore = useContestStore();
    const { loginId } = useAuthStore();
    const selectedInfo = toRef(props, 'defaultSelectedInfo');

    const apiHandler = {
      [CONTEST]: async (contestId) => {
        selectedInfo.value[CLASSIFICATION] = '';
        selectedInfo.value[SECTOR] = '';
        selectedInfo.value[EVENT] = '';

        await contestStore.fetchClassificationByContestId(contestId);
      },
      [CLASSIFICATION]: async (contestId, classificationId) => {
        selectedInfo.value[SECTOR] = '';
        selectedInfo.value[EVENT] = '';

        await contestStore.fetchSectorByParentIds(contestId, classificationId);
      },
      [SECTOR]: async (contestId, classificationId, sectorId) => {
        selectedInfo.value[EVENT] = '';

        await contestStore.fetchEventByParentIds(contestId, classificationId, sectorId);
      },
      [EVENT]: () => {},
    };

    const fetchApi = async () => {
      await contestStore.setDefaultContestInfos();
    };

    onMounted(() => {
      fetchApi();
    });

    const resetContestItem = (type) => {
      if (type === CONTEST) {
        contestStore[CLASSIFICATION] = [];
        contestStore[SECTOR] = [];
        contestStore[EVENT] = [];
      } else if (type === CLASSIFICATION) {
        contestStore[SECTOR] = [];
        contestStore[EVENT] = [];
      } else if (type === SECTOR) {
        contestStore[EVENT] = [];
      }
    };

    const onSelectItem = async (id, type, target) => {
      selectedInfo.value[type] = id;

      resetContestItem(type);

      const items = await apiHandler[type](
        selectedInfo.value[CONTEST],
        selectedInfo.value[CLASSIFICATION],
        selectedInfo.value[SECTOR],
        selectedInfo.value[EVENT],
      );

      props.selectHandler(id, type, target);

      return items;
    };

    const filteredList = computed(() => {
      const isSeoulAdmin = loginId === 'seouladmin'; // Hardcoded for 'seouladmin'

      return (list, rType) => {
        if (isSeoulAdmin && rType === CONTEST) {
          return list.filter((item) => item.id === 12); // Hardcoded id for (TEST)2024 서울시 마포 대회
        } else {
          return list;
        }
      };
    });

    return {
      contestStore,
      selectedInfo,
      renderModeInfo,
      renderInfos,
      onSelectItem,
      filteredList,
      CONTEST,
      CLASSIFICATION,
      SECTOR,
      EVENT,
    };
  },
};
</script>

<style lang="scss" scoped>
.contestSelectGroup {
  &__row {
    display: flex;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &__error {
    color: red;
  }
}
</style>
